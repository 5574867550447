<template>
    <div class="filter__details">
        <div class="columns is-vcentered">
            <div class="column is-narrow">
                <div class="filter__details_suffix">
                    <div class="columns is-vcentered">
                        <div class="column is-narrow">
                            <div class="filter__details_suffix_overall">Showing {{ itemsNumber }} of {{ itemsNumberOfOutput }}</div>
                        </div>
                        <div class="column is-narrow">
                            <div class="filter__details_suffix_label">{{ itemsNumberOutput }}</div>
                        </div>
                        <div class="column is-narrow">
                            <div class="columns is-vcentered">
                                <div class="column is-narrow">
                                    <form-select
                                        :label="''"
                                        :name="'filter-details-suffix-overall-select'"
                                        :value="Number(pageSizeSelectValue)"
                                        :placeholder="''"
                                        :items="pageSizeSelectItems"
                                        :prevent-clear="true"
                                        @input="emitFilterPageSize"
                                    ></form-select>
                                </div>
                                <div class="column is-narrow">
                                    <span class="filter__details_suffix_perpage">per page</span>
                                </div>
                            </div>
                        </div>
                        <div class="column"></div>
                    </div>
                </div>
            </div>
            <div class="column"></div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'filter-details',
  components: {
    FormSelect: () => import('@/components/default/forms/FormSelect')
  },
  props: {
    itemsNumber: {
      type: Number,
      default: 0
    },
    itemsNumberOf: {
      type: [Number, Boolean, String],
      default: 0
    },
    itemsName: {
      type: [String, Array],
      default: () => ['item', 'items']
    },
    pageSizeSelectActive: {
      type: Boolean,
      default: false
    },
    pageSizeSelectValue: {
      type: Number,
      default: 0
    },
    defaultPageSizeSelectValue: {
      type: Number
    }
  },
  computed: {
    itemsNumberOfOutput () {
      return this.itemsNumberOf || this.itemsNumber
    },
    pageSizeSelectItems () {
      let itemsArr = [
        {
          label: 20,
          value: 20
        },
        {
          label: 50,
          value: 50
        },
        {
          label: 100,
          value: 100
        },
        {
          label: 200,
          value: 200
        }
      ]

      return itemsArr
    },
    itemsNumberOutput () {
      let str
      const itemsName = this.itemsName
      const itemsNumber = this.itemsNumber

      if (itemsName.constructor === Array) {
        if (itemsNumber > 1 || itemsNumber === 0) {
          if (itemsName.length > 1) {
            str = itemsName[1]
          } else {
            str = itemsName[0]
          }
        } else {
          str = itemsName[0]
        }
      } else {
        str = itemsName
      }

      return str
    }
  },
  methods: {
    emitFilterPageSize (data) {
      this.$emit('emit-filter-page-size', data.value)
    }
  }
}
</script>

<style lang="scss">
    @import "~@/assets/scss/components/ebs/filters/filterdetails";
</style>
