<template>
    <div :class="[
        'component',
        'component__button_menu',
        `component__button_menu--style-${menuStyle}`
    ]">
        <component-button
            :align="align"
            :disabled="disabled"
            :class="'component__button_menu_expand'"
            :theme="theme"
            :icon="true"
            :icon-name="iconName"
            :icon-raw="iconRaw"
            :name="name"
            ref="togglebutton"
            @click="toggleMenu"
        ></component-button>
        <div
            v-show="isActive"
            ref="dropdownmenu"
            :class="[
                'component__button_menu_list'
            ]"
        >
            <template v-if="loading">
                <component-loading :size="'small'"></component-loading>
            </template>
            <template v-else>
                <ul>
                    <li
                        v-for="(button, index) in buttonsOutput"
                        :key="index"
                        :class="[
                        'component__button_menu_list_item',
                        !button.name && !button.key ? 'component__button_menu_list_item--separator' : ''
                    ]"
                    >
                        <template v-if="button.name || button.key">
                            <component-button
                                :align="'left'"
                                :theme="''"
                                :name="`${name}-button`"
                                :icon="button.icon"
                                :icon-name="button.iconName"
                                :icon-align="button.iconAlign"
                                :text="true"
                                :size="'tiny'"
                                :danger="button.danger"
                                @click="performEmit(button.key)"
                            >{{ toCapitalize(button.name) }}
                            </component-button>
                        </template>
                        <template v-else>
                            <div class="component__button_menu_list_item_separator"></div>
                        </template>
                    </li>
                </ul>
            </template>
        </div>
    </div>
</template>

<script>
import ComponentLoading from '@/components/default/shared/ComponentLoading'

export default {
  name: 'component-button-menu',
  components: {
    ComponentLoading,
    ComponentButton: () => import('@/components/default/shared/ComponentButton')
  },
  data () {
    return {
      isActive: false,
      loading: false
    }
  },
  props: {
    align: {
      type: String,
      default: 'left'
    },
    buttons: {
      /* array of objects
      {
           key: String,
           name: String
      }
      */
      type: Array,
      required: true,
      default: () => {
      }
    },
    iconName: {
      type: String,
      default: 'more-vertical'
    },
    iconRaw: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: 'component-button-menu-expand'
    },
    theme: {
      type: String,
      default: 'default'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loadingOnOpen: {
      type: Boolean,
      default: false
    },
    menuStyle: {
      type: String,
      default: 'first'
    }
  },
  computed: {
    buttonsOutput () {
      return this.buttons.filter(obj => obj !== null && (obj.visible !== undefined ? obj.visible : true))
    }
  },
  watch: {
    isActive (value) {
      const overlay = document.getElementById('app')

      if (value) {
        overlay.addEventListener('scroll', this.calculateDropdownPosition)
        overlay.addEventListener('resize', this.calculateDropdownPosition)
      } else {
        overlay.removeEventListener('scroll', this.calculateDropdownPosition)
        overlay.removeEventListener('resize', this.calculateDropdownPosition)
      }
    },
  },
  methods: {
    calculateDropdownPosition () {
      this.$nextTick(() => {
        const dropdownMenu = this.$refs.dropdownmenu
        if (!dropdownMenu) return
        const dropdownMenuWidth = dropdownMenu.offsetWidth
        const dropdownMenuHeight = dropdownMenu.offsetHeight

        const toggleButton = this.$refs.togglebutton.$el
        if (!toggleButton) return
        const toggleButtonWidth = toggleButton.offsetWidth
        const toggleButtonHeight = toggleButton.offsetHeight
        const toggleButtonDistanceFromTop = toggleButton.getBoundingClientRect().top
        const toggleButtonDistanceFromLeft = toggleButton.getBoundingClientRect().left

        let dropdownMenuNewStyleTopValue
        let dropdownMenuNewStyleLeftValue

        // check if the bottom side of menu is out of the window
        if (window.innerHeight - toggleButtonDistanceFromTop - toggleButtonHeight < dropdownMenuHeight) {
          dropdownMenuNewStyleTopValue = toggleButtonDistanceFromTop - dropdownMenuHeight
        } else {
          dropdownMenuNewStyleTopValue = toggleButtonHeight + toggleButtonDistanceFromTop
        }

        // check if the right side of menu is out of the window
        if (
          this.dropdownPosition === 'right' && window.innerWidth - toggleButtonDistanceFromLeft > dropdownMenuWidth
        ) {
          dropdownMenuNewStyleLeftValue = toggleButtonDistanceFromLeft
        } else {
          dropdownMenuNewStyleLeftValue = (
            toggleButtonDistanceFromLeft + toggleButtonWidth - dropdownMenuWidth
          )
        }

        dropdownMenu.style.top = `${dropdownMenuNewStyleTopValue}px`
        dropdownMenu.style.left = `${dropdownMenuNewStyleLeftValue}px`
      })
    },
    autoCloseMenu (event) {
      const path = event.path || event.composedPath()

      if (!path || !this.isActive) return
      let closeMenu = true
      for (let i = 0; i < path.length; i++) {
        if (!path[i].classList) continue
        if (path[i].classList.contains('component__button_menu')) {
          closeMenu = false
        }
      }
      if (closeMenu) {
        this.closeMenu()
      }
    },
    startLoading () {
      this.loading = true
    },
    stopLoading () {
      this.loading = false
    },
    openMenu () {
      this.isActive = true
      this.$emit('emit-open')
      if (this.loadingOnOpen) {
        this.startLoading()
      }

      this.calculateDropdownPosition()
    },
    closeMenu () {
      this.$emit('emit-close')
      this.isActive = false
    },
    toggleMenu () {
      if (this.isActive) {
        this.closeMenu()
      } else {
        this.$eventBus.$emit('close-component-button-menu')
        this.openMenu()
      }
    },
    performEmit (value) {
      this.$emit(`emit-${value}`)
      this.$emit('emit', value)
      this.closeMenu()
    }
  },
  mounted () {
    this.$eventBus.$on('close-component-button-menu', this.closeMenu)
    const overlay = document.getElementById('app')
    overlay.addEventListener('click', this.autoCloseMenu)
  },
  beforeDestroy () {
    this.$eventBus.$off('close-component-button-menu')
    const overlay = document.getElementById('app')
    overlay.removeEventListener('click', this.closeMenu)
  }
}
</script>

<style lang="scss">
    @import "~@/assets/scss/components/default/shared/componentbuttonmenu";
</style>
