var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'component',
    'component__button_menu',
    `component__button_menu--style-${_vm.menuStyle}`
]},[_c('component-button',{ref:"togglebutton",class:'component__button_menu_expand',attrs:{"align":_vm.align,"disabled":_vm.disabled,"theme":_vm.theme,"icon":true,"icon-name":_vm.iconName,"icon-raw":_vm.iconRaw,"name":_vm.name},on:{"click":_vm.toggleMenu}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isActive),expression:"isActive"}],ref:"dropdownmenu",class:[
            'component__button_menu_list'
        ]},[(_vm.loading)?[_c('component-loading',{attrs:{"size":'small'}})]:[_c('ul',_vm._l((_vm.buttonsOutput),function(button,index){return _c('li',{key:index,class:[
                    'component__button_menu_list_item',
                    !button.name && !button.key ? 'component__button_menu_list_item--separator' : ''
                ]},[(button.name || button.key)?[_c('component-button',{attrs:{"align":'left',"theme":'',"name":`${_vm.name}-button`,"icon":button.icon,"icon-name":button.iconName,"icon-align":button.iconAlign,"text":true,"size":'tiny',"danger":button.danger},on:{"click":function($event){return _vm.performEmit(button.key)}}},[_vm._v(_vm._s(_vm.toCapitalize(button.name))+" ")])]:[_c('div',{staticClass:"component__button_menu_list_item_separator"})]],2)}),0)]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }