<template>
    <thead class="list listtablehead">
    <tr>
        <th
            v-if="checkbox"
            class="listtablehead__checkbox checkbox"
        >
            <list-table-head-check
                :disabled="disabled"
                :name="checkboxName"
                :emit-list-name="listEmitName"
                :checked="checkboxChecked"
            />
        </th>
        <th
            v-for="(label, index) in labels"
            :class="`listtablehead__${toUnicase(label.constructor === Object ? (label.key && label.key.constructor === Array ? label.key[0] : label.key) : (label ? toUnicase(label) : 'blank'))}`"
            :key="index"
        >
            <div class="columns is-vcentered">
                <div class="column">
                    <div class="listtablehead__label">
                        {{label.constructor === Object ? label.title : label | toCapitalize}}
                    </div>
                </div>
                <template v-if="label.sortable">
                    <div class="column is-narrow">
                        <div class="listtablehead__sort">
                            <component-button
                                :class="['listtablehead__sort_button', 'listtablehead__sort_button--asc']"
                                :theme="(sortBy === label.key) && (sortDirection === 'asc') ? 'primary' : 'default'"
                                :name="'list-table-head-sort-asc'"
                                :icon="true"
                                :icon-raw="true"
                                :icon-name="'chevron-up'"
                                @click="emitSort({ sortBy: label.key, sortType: label.type, sortDirection: 'asc', sortSubBy: label.subKey, sortSubType: label.subType, sortQueryable: label.queryable })"
                            />
                            <component-button
                                :class="['listtablehead__sort_button', 'listtablehead__sort_button--desc']"
                                :theme="(sortBy === label.key) && (sortDirection === 'desc') ? 'primary' : 'default'"
                                :name="'list-table-head-sort-desc'"
                                :icon="true"
                                :icon-raw="true"
                                :icon-name="'chevron-down'"
                                @click="emitSort({ sortBy: label.key, sortType: label.type, sortDirection: 'desc', sortSubBy: label.subKey, sortSubType: label.subType, sortQueryable: label.queryable })"
                            />
                        </div>
                    </div>
                </template>
            </div>
        </th>
    </tr>
    </thead>
</template>

<script>
import ListTableHeadCheck from '@/components/default/shared/ListTableHeadCheck'

export default {
  components: {
    ComponentButton: () => import('@/components/default/shared/ComponentButton'),
    ListTableHeadCheck
  },
  name: 'list-table-head',
  props: {
    labels: {
      /*
      Array of String
      [String]

      or

      Array of Object
      {
         key: String,
         name: String,
         type: one of ['string', 'number', 'date', 'array', 'object'],
         subKey: String,
         subType: one of ['string', 'number', 'date', 'array', 'object'],
         sortable: Boolean
      }
      */
      type: Array,
      required: true
    },
    checkbox: {
      type: Boolean,
      default: false
    },
    checkboxName: {
      type: String
    },
    checkboxChecked: {
      type: Boolean,
      default: false
    },
    listEmitName: {
      type: String,
      default: 'list-head-check'
    },
    disabled: {
      type: Boolean
    },
    sortBy: {
      type: [String, Array]
    },
    sortDirection: {
      type: String
    }
  },
  methods: {
    emitSort (data) {
      this.$emit('emit-sort', data)
    }
  }
}
</script>

<style lang="scss">
    @import "~@/assets/scss/components/default/shared/listtablehead";
</style>
