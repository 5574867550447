import { toUncapitalise } from '@/functions'
import api from '@/api'
import axios from 'axios'
import * as rules from 'vee-validate/dist/rules.esm.js'
import { UUID } from '@/UUID'

const data = {
  data: {
    file: '',
    users: [],
    roles: ['learner'],
    orgUnitId: '',
    orgUnitName: '',
    workspaceId: '',
    workspaceName: '',
    customerId: '',
    customerName: '',
    suggestedCourses: []
  },
  custom: {
    blockRoles: false,
    blockWorkspace: false,
    blockCustomer: false,
    inviteProcessBlocked: false,
    inviteProcessPending: false,
    inviteProcessCompleted: false,
    pagination: {
      page: 1,
      pageSize: 12
    },
    orgUnitsSuggestedCourses: [],
    groupsSuggestedCourses: [],
    viewMode: 'all'
  }
}

const lmsSuperadminUsersBulk = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getLmsSuperadminUsersBulkFile: state => {
      return state.data.file
    },
    getLmsSuperadminUsersBulkUsers: state => {
      const viewMode = state.custom.viewMode
      let users = JSON.parse(JSON.stringify(state.data.users))
      if (viewMode && viewMode !== 'all') {
        users = users.filter(obj => obj.status === viewMode)
      }
      return users
    },
    getLmsSuperadminUsersBulkUsersCustomFields: state => {
      return state.data.users.length ? state.data.users[0].customFields : []
    },
    getLmsSuperadminUsersBulkUsersPage: (state, getters) => {
      const page = state.custom.pagination.page
      const pageSize = state.custom.pagination.pageSize
      const users = JSON.parse(JSON.stringify(getters.getLmsSuperadminUsersBulkUsers))
      const sliceStart = ((page * pageSize) - pageSize)
      const sliceEnd = (page * pageSize)
      let selectedUsers = users.slice(sliceStart, sliceEnd)
      return selectedUsers
    },
    getLmsSuperadminUsersBulkUsersPagination: (state, getters) => {
      let users = JSON.parse(JSON.stringify(getters.getLmsSuperadminUsersBulkUsers))

      return {
        page: state.custom.pagination.page,
        pageSize: state.custom.pagination.pageSize,
        totalResults: users.length
      }
    },
    getLmsSuperadminUsersBulkUsersAmount: state => {
      return state.data.users.length
    },
    getLmsSuperadminUsersBulkUsersAmountByKey: state => key => {
      return state.data.users.filter(obj => obj.status === key).length
    },
    getLmsSuperadminUsersBulkUsersAmountExludedByKey: state => keys => {
      return state.data.users.filter(obj => !keys.includes(obj.status)).length
    },
    getLmsSuperadminUsersBulkRoles: state => {
      return state.data.roles
    },
    getLmsSuperadminUsersBulkWorkspaceId: state => {
      return state.data.workspaceId
    },
    getLmsSuperadminUsersBulkWorkspaceName: state => {
      return state.data.workspaceName
    },
    getLmsSuperadminUsersBulkCustomerId: state => {
      return state.data.customerId
    },
    getLmsSuperadminUsersBulkCustomerName: state => {
      return state.data.customerName
    },
    getLmsSuperadminUsersBulkCustomBlockRoles: state => {
      return state.custom.blockRoles
    },
    getLmsSuperadminUsersBulkCustomBlockWorkspace: state => {
      return state.custom.blockWorkspace
    },
    getLmsSuperadminUsersBulkCustomBlockCustomer: state => {
      return state.custom.blockCustomer
    },
    getLmsSuperadminUsersBulkCustomInviteProcessBlocked: state => {
      return state.custom.inviteProcessBlocked
    },
    getLmsSuperadminUsersBulkCustomInviteProcessPending: state => {
      return state.custom.inviteProcessPending
    },
    getLmsSuperadminUsersBulkCustomInviteProcessCompleted: state => {
      return state.custom.inviteProcessCompleted
    },
    getLmsSuperadminUsersBulkCustomViewMode: state => {
      return state.custom.viewMode
    },
    getLmsSuperadminUsersBulkHasUsersAvailableToInvite: state => {
      return Boolean(state.data.users.filter(obj => (obj.firstName && obj.lastName && obj.emailAddress) && (obj.status === 'ready' || obj.status === 'not-invited')).length)
    },
    getLmsSuperadminUsersBulkHasUsersInEditMode: state => {
      return Boolean(state.data.users.filter(obj => obj.editMode === true).length)
    }
  },
  mutations: {
    LMS_SUPERADMIN_USERS_BULK_SET_FILE (state, payload) {
      state.data.file = payload
    },
    LMS_SUPERADMIN_USERS_BULK_SET_USER (state) {
      const roles = JSON.parse(JSON.stringify(state.data.roles))
      const selectedOrgUnit = {
        id: state.data.orgUnitId,
        name: state.data.orgUnitName
      }
      const suggestedCourses = JSON.parse(JSON.stringify(state.data.suggestedCourses))
      state.data.users.push({
        tableId: UUID.v4(),
        firstName: '',
        lastName: '',
        emailAddress: '',
        status: 'ready',
        workspaceGroups: [],
        suggestedCourses: suggestedCourses,
        customFields: [],
        roles: roles,
        managerOrganisationUnits: [],
        unit: selectedOrgUnit,
        disabled: false,
        editMode: true,
        invalidUnit: '',
        invalidWorkspaceGroups: []
      })
    },
    LMS_SUPERADMIN_USERS_BULK_SET_USER_FIRSTNAME (state, payload) {
      const users = state.data.users
      let selectedUser = users ? users.find(obj => obj.tableId === payload.index) : null
      if (selectedUser) {
        selectedUser.firstName = payload.value
      }
    },
    LMS_SUPERADMIN_USERS_BULK_SET_USER_LASTNAME (state, payload) {
      const users = state.data.users
      let selectedUser = users ? users.find(obj => obj.tableId === payload.index) : null
      if (selectedUser) {
        selectedUser.lastName = payload.value
      }
    },
    LMS_SUPERADMIN_USERS_BULK_SET_USER_EMAIL (state, payload) {
      const users = state.data.users
      let selectedUser = users ? users.find(obj => obj.tableId === payload.index) : null
      if (selectedUser) {
        selectedUser.emailAddress = payload.value
      }
    },
    LMS_SUPERADMIN_USERS_BULK_SET_USER_EDITMODE (state, payload) {
      const users = state.data.users
      let selectedUser = users ? users.find(obj => obj.tableId === payload.index) : null
      if (selectedUser) {
        selectedUser.editMode = payload.data
      }
    },
    LMS_SUPERADMIN_USERS_BULK_SET_USER_ROLES (state, payload) {
      const users = state.data.users
      const payloadRoles = payload.data
      let selectedUser = users ? users.find(obj => obj.tableId === payload.index) : null
      if (!payloadRoles.includes('manager')) {
        selectedUser.managerOrganisationUnits = []
      }
      if (selectedUser) {
        if (payloadRoles.includes('admin') && payloadRoles.includes('manager')) {
          if (payloadRoles.findIndex(obj => obj === 'admin') > payloadRoles.findIndex(obj => obj === 'manager')) {
            payloadRoles.splice(payloadRoles.findIndex(obj => obj === 'manager'), 1)
          } else {
            payloadRoles.splice(payloadRoles.findIndex(obj => obj === 'admin'), 1)
          }
        }
        selectedUser.roles = payloadRoles
      }
    },
    LMS_SUPERADMIN_USERS_BULK_SET_USER_MANAGER_ORGANISATION_UNITS (state, payload) {
      const users = state.data.users
      let selectedUser = users ? users.find(obj => obj.tableId === payload.index) : null
      if (selectedUser) {
        selectedUser.managerOrganisationUnits = payload.data
      }
    },
    LMS_SUPERADMIN_USERS_BULK_SET_USER_GROUPS (state, payload) {
      const users = state.data.users
      let selectedUser = users ? users.find(obj => obj.tableId === payload.index) : null
      if (selectedUser) {
        selectedUser.workspaceGroups = payload.data
      }
    },
    LMS_SUPERADMIN_USERS_BULK_SET_USER_ORGUNIT (state, payload) {
      const users = state.data.users
      let selectedUser = users ? users.find(obj => obj.tableId === payload.index) : null
      if (selectedUser) {
        selectedUser.unit = payload.data || ''
      }
    },
    LMS_SUPERADMIN_USERS_BULK_SET_USER_CUSTOMFIELD (state, payload) {
      const users = state.data.users
      let selectedUser = users ? users.find(obj => obj.tableId === payload.index) : null

      let customFields = selectedUser ? JSON.parse(JSON.stringify(selectedUser.customFields)) : []
      let existingCustomFieldIndex = customFields.findIndex(obj => obj.id === payload.id)

      if (existingCustomFieldIndex > -1) {
        if (payload.value !== '') {
          customFields[existingCustomFieldIndex].value = payload.value
        } else {
          customFields.splice(existingCustomFieldIndex, 1)
        }
      } else {
        customFields.push({
          id: payload.id,
          value: payload.value
        })
      }

      selectedUser.customFields = customFields
    },
    LMS_SUPERADMIN_USERS_BULK_SET_USERS_DEFAULT_ROLES (state) {
      const users = state.data.users

      const defaultRoles = JSON.parse(JSON.stringify(state.data.roles))

      for (let index in users) {
        users[index].roles = users[index].roles.length ? users[index].roles : defaultRoles
      }
    },
    LMS_SUPERADMIN_USERS_BULK_SET_USER_STATUS (state, payload) {
      const users = state.data.users
      let selectedUser = users ? users.find(obj => obj.tableId === payload.index) : null

      if (selectedUser) {
        selectedUser.status = payload.value
      }
    },
    LMS_SUPERADMIN_USERS_BULK_SET_USER_DISABLED (state, payload) {
      const users = state.data.users
      let selectedUser = users ? users.find(obj => obj.tableId === payload.index) : null

      if (selectedUser) {
        selectedUser.disabled = payload.value
      }
    },
    LMS_SUPERADMIN_USERS_BULK_SET_USERS (state, payload) {
      state.data.users = payload
      state.custom.pagination.page = 1
    },
    LMS_SUPERADMIN_USERS_BULK_SET_ROLES (state, payload) {
      let roles = state.data.roles
      const existsIndex = roles.indexOf(payload.name)

      if (existsIndex > -1) {
        roles.splice(existsIndex, 1)
      } else {
        if (payload.value) {
          roles.push(payload.name)
        }
      }
    },
    LMS_SUPERADMIN_USERS_BULK_SET_WORKSPACEID (state, payload) {
      state.data.workspaceId = payload
    },
    LMS_SUPERADMIN_USERS_BULK_SET_WORKSPACENAME (state, payload) {
      state.data.workspaceName = payload
    },
    LMS_SUPERADMIN_USERS_BULK_SET_SUGGESTEDCOURSES (state, payload) {
      state.data.suggestedCourses = payload
    },
    LMS_SUPERADMIN_USERS_BULK_SET_ORGUNITID (state, payload) {
      state.data.orgUnitId = payload
    },
    LMS_SUPERADMIN_USERS_BULK_SET_ORGUNITNAME (state, payload) {
      state.data.orgUnitName = payload
    },
    LMS_SUPERADMIN_USERS_BULK_SET_CUSTOMERID (state, payload) {
      state.data.customerId = payload
    },
    LMS_SUPERADMIN_USERS_BULK_SET_CUSTOMERNAME (state, payload) {
      state.data.customerName = payload
    },
    LMS_SUPERADMIN_USERS_BULK_CLEAR (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
      state.custom = JSON.parse(JSON.stringify(data.custom))
    },
    LMS_SUPERADMIN_USERS_BULK_CLEAR_USERS (state) {
      state.data.users = []
    },
    LMS_SUPERADMIN_USERS_BULK_CLEAR_USER_INVALIDWORKSPACEGROUPS (state, payload) {
      const users = state.data.users
      let selectedUser = users ? users.find(obj => obj.tableId === payload) : null

      if (selectedUser) {
        selectedUser.invalidWorkspaceGroups = []
      }
    },
    LMS_SUPERADMIN_USERS_BULK_CLEAR_USER_INVALIDORGUNIT (state, payload) {
      const users = state.data.users
      let selectedUser = users ? users.find(obj => obj.tableId === payload) : null

      if (selectedUser) {
        selectedUser.invalidUnit = ''
      }
    },
    LMS_SUPERADMIN_USERS_BULK_SET_CUSTOM_BLOCKROLES (state, payload) {
      state.custom.blockRoles = payload
    },
    LMS_SUPERADMIN_USERS_BULK_SET_CUSTOM_BLOCKWORKSPACE (state, payload) {
      state.custom.blockWorkspace = payload
    },
    LMS_SUPERADMIN_USERS_BULK_SET_CUSTOM_BLOCKCUSTOMER (state, payload) {
      state.custom.blockCustomer = payload
    },
    LMS_SUPERADMIN_USERS_BULK_SET_CUSTOM_INVITEPROCESSBLOCKED (state, payload) {
      state.custom.inviteProcessBlocked = payload
    },
    LMS_SUPERADMIN_USERS_BULK_SET_CUSTOM_INVITEPROCESSPENDING (state, payload) {
      state.custom.inviteProcessPending = payload
    },
    LMS_SUPERADMIN_USERS_BULK_SET_CUSTOM_INVITEPROCESSCOMPLETED (state, payload) {
      state.custom.inviteProcessCompleted = payload
    },
    LMS_SUPERADMIN_USERS_BULK_SET_CUSTOM_PAGINATION_PAGE (state, payload) {
      state.custom.pagination.page = payload
    },
    LMS_SUPERADMIN_USERS_BULK_SET_CUSTOM_VIEWMODE (state, payload) {
      state.custom.viewMode = payload
    },
    LMS_SUPERADMIN_USERS_BULK_SET_CUSTOM_ORGUNITS_SUGGESTEDCOURSES (state, payload) {
      state.custom.orgUnitsSuggestedCourses = payload
    },
    LMS_SUPERADMIN_USERS_BULK_SET_CUSTOM_GROUPS_SUGGESTEDCOURSES (state, payload) {
      state.custom.groupsSuggestedCourses = payload
    },
    LMS_SUPERADMIN_USERS_BULK_REMOVE_USER (state, payload) {
      const users = state.data.users
      let selectedUserIndex = users ? users.findIndex(obj => obj.tableId === payload) : null

      state.data.users.splice(selectedUserIndex, 1)
    }
  },
  actions: {
    lmsSuperadminUsersBulkClear ({ commit }, data) {
      commit('LMS_SUPERADMIN_USERS_BULK_CLEAR', data)
    },
    lmsSuperadminUsersBulkRemoveUser ({ commit, state, getters }, data) {
      commit('LMS_SUPERADMIN_USERS_BULK_REMOVE_USER', data)
      const users = JSON.parse(JSON.stringify(getters.getLmsSuperadminUsersBulkUsers))
      const pageSize = state.custom.pagination.pageSize
      const page = state.custom.pagination.page
      const totalResults = users.length

      if (Math.ceil(totalResults / pageSize) < page) {
        commit('LMS_SUPERADMIN_USERS_BULK_SET_CUSTOM_PAGINATION_PAGE', page - 1)
      }
    },
    lmsSuperadminUsersBulkSetFile ({ commit }, data) {
      commit('LMS_SUPERADMIN_USERS_BULK_SET_FILE', data)
    },
    lmsSuperadminUsersBulkSetUser ({ commit, state, getters }, data) {
      commit('LMS_SUPERADMIN_USERS_BULK_SET_USER', data)

      const users = JSON.parse(JSON.stringify(getters.getLmsSuperadminUsersBulkUsers))
      const pageSize = state.custom.pagination.pageSize
      const totalResults = users.length
      const lastPage = Math.ceil(totalResults / pageSize)

      commit('LMS_SUPERADMIN_USERS_BULK_SET_CUSTOM_PAGINATION_PAGE', lastPage)
    },
    lmsSuperadminUsersBulkSetUserFirstName ({ commit }, data) {
      commit('LMS_SUPERADMIN_USERS_BULK_SET_USER_FIRSTNAME', data)
    },
    lmsSuperadminUsersBulkSetUserLastName ({ commit }, data) {
      commit('LMS_SUPERADMIN_USERS_BULK_SET_USER_LASTNAME', data)
    },
    lmsSuperadminUsersBulkSetUserEmail ({ commit }, data) {
      commit('LMS_SUPERADMIN_USERS_BULK_SET_USER_EMAIL', data)
    },
    lmsSuperadminUsersBulkSetUserEditMode ({ commit }, data) {
      commit('LMS_SUPERADMIN_USERS_BULK_SET_USER_EDITMODE', data)
    },
    lmsSuperadminUsersBulkClearUserInvalidWorkspaceGroups ({ commit }, data) {
      commit('LMS_SUPERADMIN_USERS_BULK_CLEAR_USER_INVALIDWORKSPACEGROUPS', data)
    },
    lmsSuperadminUsersBulkClearUserInvalidOrgUnit ({ commit }, data) {
      commit('LMS_SUPERADMIN_USERS_BULK_CLEAR_USER_INVALIDORGUNIT', data)
    },
    lmsSuperadminUsersBulkSetUserStatus ({ commit }, data) {
      commit('LMS_SUPERADMIN_USERS_BULK_SET_USER_STATUS', data)
    },
    lmsSuperadminUsersBulkSetUserRoles ({ commit }, data) {
      commit('LMS_SUPERADMIN_USERS_BULK_SET_USER_ROLES', data)
    },
    lmsSuperadminUsersBulkSetUserManagerOrganisationUnits ({ commit }, data) {
      commit('LMS_SUPERADMIN_USERS_BULK_SET_USER_MANAGER_ORGANISATION_UNITS', data)
    },
    lmsSuperadminUsersBulkSetUserGroups ({ commit }, data) {
      commit('LMS_SUPERADMIN_USERS_BULK_SET_USER_GROUPS', data)
    },
    lmsSuperadminUsersBulkSetUserOrgUnit ({ commit }, data) {
      commit('LMS_SUPERADMIN_USERS_BULK_SET_USER_ORGUNIT', data)
    },
    lmsSuperadminUsersBulkSetUserCustomField ({ commit }, data) {
      commit('LMS_SUPERADMIN_USERS_BULK_SET_USER_CUSTOMFIELD', data)
    },
    lmsSuperadminUsersBulkSetUsersDefaultRoles ({ commit }) {
      commit('LMS_SUPERADMIN_USERS_BULK_SET_USERS_DEFAULT_ROLES', data)
    },
    lmsSuperadminUsersBulkSetUsers ({ commit, getters, dispatch, state }, data) {
      return new Promise(() => {
        commit('LMS_SUPERADMIN_USERS_BULK_CLEAR_USERS')
        commit('LMS_SUPERADMIN_USERS_BULK_SET_CUSTOM_BLOCKROLES', false)
        commit('LMS_SUPERADMIN_USERS_BULK_SET_CUSTOM_BLOCKWORKSPACE', false)
        commit('LMS_SUPERADMIN_USERS_BULK_SET_CUSTOM_BLOCKCUSTOMER', false)

        let validUsers = []
        const selectedWorkspaceName = state.data.workspaceName
        const availableGroups = JSON.parse(JSON.stringify(getters.getLmsSuperadminGroups))
        const rawAvailableOrgUnits = JSON.parse(JSON.stringify(getters.getLmsSuperadminOrgUnitsMappedLabelValue))
        const defaultRoles = state.data.roles
        const availableOrgUnits = rawAvailableOrgUnits.map(obj => {
          return {
            id: obj.value,
            name: obj.label,
            namePath: `${selectedWorkspaceName} / ${obj.parentPath}`
          }
        })
        let availableContentRequestsArr = []
        let availableContentRequestsCounter = 0
        const suggestedCourses = JSON.parse(JSON.stringify(state.data.suggestedCourses))
        const workspaceCustomFields = getters.getLmsSuperadminWorkspaceCustomFieldsData
        const selectedOrgUnit = {
          id: state.data.orgUnitId,
          name: state.data.orgUnitName
        }
        let csvEditableFields = workspaceCustomFields.filter(obj => obj.type !== 'multiple' && obj.type !== 'select')
        let csvNonEditableFields = workspaceCustomFields.filter(obj => obj.type === 'multiple' || obj.type === 'select')
        let validDefaultCustomFields = [...csvEditableFields, ...csvNonEditableFields]

        const performLoop = () => {
          for (let index in data) {
            let dataStatus = 'ready'
            let validUserGroups = []
            let invalidUserGroups = []
            let validUserOrgUnit = ''
            let invalidUserOrgUnit = ''
            let validCustomFields = []
            let dataFirstName = data[index][0]
            let dataLastName = data[index][1]
            let dataEmailAddress = data[index][2].replace(/\s/g, '').toLowerCase()
            let dataOrgUnit = data[index][3]
            let dataGroups = data[index][4]
            let dataCustomFields = JSON.parse(JSON.stringify(data[index]))
            dataCustomFields.splice(0, 5)

            validCustomFields = validDefaultCustomFields.map((obj, index) => {
              let fieldValue = dataCustomFields[index] ? dataCustomFields[index].replace(/(\r\n|\n|\r)/gm, '') : ''

              if (fieldValue.toLowerCase() === 'true') {
                fieldValue = true
              } else if (fieldValue.toLowerCase() === 'false') {
                fieldValue = false
              }

              // validate custom fields
              // check if is mandatory
              if (obj.required && !fieldValue) {
                dataStatus = 'invalid'
              }

              // check data type
              if (fieldValue) {
                if (obj.customValidation) {
                  if (
                    (obj.customValidation.maxLength && fieldValue.length > obj.customValidation.maxLength) ||
                  (obj.customValidation.minLength && fieldValue.length < obj.customValidation.minLength)
                  ) {
                    dataStatus = 'invalid'
                  }
                }
                if (obj.type === 'alpha') {
                  if (!rules.alpha.validate(fieldValue)) {
                    dataStatus = 'invalid'
                  }
                } else if (obj.type === 'numeric' || obj.type === 'number') {
                  if (!rules.numeric.validate(fieldValue)) {
                    dataStatus = 'invalid'
                  }
                } else if (obj.type === 'email') {
                  if (!rules.email.validate(fieldValue)) {
                    dataStatus = 'invalid'
                  }
                } else if (obj.type === 'date') {
                  if (!rules.date_format.validate(fieldValue, { format: 'yyyy-MM-dd' })) {
                    dataStatus = 'invalid'
                  }
                } else {
                  if (rules[obj.type] && !rules[obj.type].validate(fieldValue)) {
                    dataStatus = 'invalid'
                  }
                }
              }

              return {
                id: obj.id,
                value: fieldValue
              }
            })

            if (dataGroups) {
              dataGroups = dataGroups.replace('.', ',')
              let userGroups = dataGroups ? dataGroups.split(',') : []

              for (let j in userGroups) {
                const userGroupName = userGroups[j].toLowerCase().replace(/(\r\n|\n|\r)/gm, '')
                const selectedGroup = availableGroups.find(obj => obj.name.toLowerCase() === userGroupName)

                if (selectedGroup) {
                  validUserGroups.push({
                    id: selectedGroup.id,
                    name: selectedGroup.name
                  })
                } else if (userGroupName) {
                  invalidUserGroups.push(userGroupName)
                  dataStatus = 'invalid'
                }
              }
            }

            if (dataOrgUnit) {
              let userOrgUnit = dataOrgUnit

              const userOrgUnitName = userOrgUnit
              const selectedOrgUnit = availableOrgUnits.find(obj => obj.namePath === userOrgUnitName)

              if (selectedOrgUnit) {
                validUserOrgUnit = {
                  id: selectedOrgUnit.id,
                  name: selectedOrgUnit.name
                }
              } else if (userOrgUnitName) {
                invalidUserOrgUnit = userOrgUnitName
                dataStatus = 'invalid'
              }
            }

            // validate fields
            if (!dataFirstName || !dataLastName || !dataEmailAddress) {
              dataStatus = 'invalid'
            }
            if (dataEmailAddress && !rules.email.validate(dataEmailAddress)) {
              dataStatus = 'invalid'
            }

            validUsers.push({
              tableId: UUID.v4(),
              firstName: dataFirstName,
              lastName: dataLastName,
              emailAddress: dataEmailAddress,
              workspaceGroups: validUserGroups,
              unit: validUserOrgUnit || selectedOrgUnit,
              invalidUnit: invalidUserOrgUnit,
              invalidWorkspaceGroups: invalidUserGroups,
              suggestedCourses: validUserOrgUnit ? [] : suggestedCourses,
              customFields: validCustomFields,
              roles: defaultRoles,
              managerOrganisationUnits: [],
              status: dataStatus,
              disabled: false,
              editMode: false
            })
          }

          commit('LMS_SUPERADMIN_USERS_BULK_SET_USERS', validUsers)
        }

        if (!availableGroups.length) {
          availableContentRequestsArr.push({ name: 'lmsSuperadminGroupsGet', data: getters.getLmsSuperadminUsersBulkWorkspaceId })
        }
        if (!availableOrgUnits.length) {
          availableContentRequestsArr.push({ name: 'lmsSuperadminOrgUnitsGet', data: getters.getLmsSuperadminUsersBulkWorkspaceId })
        }

        if (availableContentRequestsArr.length) {
          for (let action of availableContentRequestsArr) {
            dispatch(action.name, action.data).finally(() => {
              availableContentRequestsCounter++
              if (Number(availableContentRequestsCounter) === Number(availableContentRequestsArr.length)) {
                performLoop()
              }
            })
          }
        } else {
          performLoop()
        }
      })
    },
    lmsSuperadminUsersBulkSetRoles ({ commit }, data) {
      commit('LMS_SUPERADMIN_USERS_BULK_SET_ROLES', data)
    },
    lmsSuperadminUsersBulkSetWorkspaceId ({ commit }, data) {
      commit('LMS_SUPERADMIN_USERS_BULK_SET_WORKSPACEID', data)
    },
    lmsSuperadminUsersBulkSetWorkspaceName ({ commit }, data) {
      commit('LMS_SUPERADMIN_USERS_BULK_SET_WORKSPACENAME', data)
    },
    lmsSuperadminUsersBulkSetOrgUnitId ({ commit }, data) {
      commit('LMS_SUPERADMIN_USERS_BULK_SET_ORGUNITID', data)
    },
    lmsSuperadminUsersBulkSetOrgUnitName ({ commit }, data) {
      commit('LMS_SUPERADMIN_USERS_BULK_SET_ORGUNITNAME', data)
    },
    lmsSuperadminUsersBulkSetCustomerId ({ commit }, data) {
      commit('LMS_SUPERADMIN_USERS_BULK_SET_CUSTOMERID', data)
    },
    lmsSuperadminUsersBulkSetCustomerName ({ commit }, data) {
      commit('LMS_SUPERADMIN_USERS_BULK_SET_CUSTOMERNAME', data)
    },
    lmsSuperadminUsersBulkSetCustomBlockWorkspace ({ commit }, data) {
      commit('LMS_SUPERADMIN_USERS_BULK_SET_CUSTOM_BLOCKWORKSPACE', data)
    },
    lmsSuperadminUsersBulkSetCustomBlockCustomer ({ commit }, data) {
      commit('LMS_SUPERADMIN_USERS_BULK_SET_CUSTOM_BLOCKCUSTOMER', data)
    },
    lmsSuperadminUsersBulkSetCustomInviteProcessBlocked ({ commit }, data) {
      commit('LMS_SUPERADMIN_USERS_BULK_SET_CUSTOM_INVITEPROCESSBLOCKED', data)
    },
    lmsSuperadminUsersBulkSetCustomInviteProcessPending ({ commit }, data) {
      commit('LMS_SUPERADMIN_USERS_BULK_SET_CUSTOM_INVITEPROCESSPENDING', data)
    },
    lmsSuperadminUsersBulkSetCustomInviteProcessCompleted ({ commit }, data) {
      commit('LMS_SUPERADMIN_USERS_BULK_SET_CUSTOM_INVITEPROCESSCOMPLETED', data)
    },
    lmsSuperadminUsersBulkSetCustomPaginationPage ({ commit }, data) {
      commit('LMS_SUPERADMIN_USERS_BULK_SET_CUSTOM_PAGINATION_PAGE', data)
    },
    lmsSuperadminUsersBulkSetCustomOrgUnitsSuggestedCourses ({ commit }, data) {
      commit('LMS_SUPERADMIN_USERS_BULK_SET_CUSTOM_ORGUNITS_SUGGESTEDCOURSES', data)
    },
    lmsSuperadminUsersBulkSetCustomGroupsSuggestedCourses ({ commit }, data) {
      commit('LMS_SUPERADMIN_USERS_BULK_SET_CUSTOM_GROUPS_SUGGESTEDCOURSES', data)
    },
    lmsSuperadminUsersBulkSetCustomViewMode ({ commit }, data) {
      commit('LMS_SUPERADMIN_USERS_BULK_SET_CUSTOM_VIEWMODE', data)
      commit('LMS_SUPERADMIN_USERS_BULK_SET_CUSTOM_PAGINATION_PAGE', 1)
    },
    lmsSuperadminUsersBulkCSVDownload ({ getters }) {
      const workspaceCustomFields = getters.getLmsSuperadminWorkspaceCustomFieldsData
      let labelsArr = [
        ['first name', 'last name', 'email', 'groups']
      ]
      let outputData = ''

      for (let field of workspaceCustomFields) {
        if (field.type !== 'select' && field.type !== 'multiple') {
          let fieldName = field.name.replace(/[,;]/g, ' ')
          fieldName = toUncapitalise(fieldName)
          fieldName += field.required ? '*' : ''
          fieldName += field.type === 'text' ? ` (free text)` : ''
          fieldName += field.type === 'alpha' ? ` (a to z)` : ''
          fieldName += field.type !== 'text' && field.type !== 'alpha' ? ` (${field.type})` : ''
          fieldName += field.type === 'checkbox' ? ' true/false' : ''
          fieldName += field.type === 'date' ? ' eg. 2020-12-31' : ''

          labelsArr[0].push(fieldName)
        }
      }

      labelsArr.forEach(function (arr) {
        let row = arr.join(',')
        outputData += row + '\n'
      })

      if (!window.navigator.msSaveOrOpenBlob) {
        const url = window.URL.createObjectURL(new Blob([outputData], { type: 'text/csv;charset=utf-8;' }))
        const link = document.getElementById('csv-file-download')
        link.href = url
        link.setAttribute('download', `csv-users-template.csv`)
        document.body.appendChild(link)
        link.click()
      } else {
        window.navigator.msSaveOrOpenBlob(new Blob([outputData]), 'download-ics-file')
      }
    },
    lmsSuperadminUsersBulkXLSDownload (ctx, data) {
      return new Promise(() => {
        const workspaceId = data.workspaceId
        const workspaceName = data.workspaceName

        axios({
          method: 'get',
          url: `${api.sso}/v2/bulk-invite-file/${workspaceId}`,
          responseType: 'arraybuffer'
        }).then(response => {
          if (!window.navigator.msSaveOrOpenBlob) {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `invite-users-template-${workspaceName}.xlsx`)
            document.body.appendChild(link)
            link.click()
          } else {
            window.navigator.msSaveOrOpenBlob(new Blob([response.data]), `invite-users-template-${workspaceName}.xlsx`)
          }
        }).catch(error => {
          console.error(new Error(error))
        })
      })
    },
    lmsSuperadminUsersBulkInvite ({ state, dispatch, getters }) {
      return new Promise((resolve, reject) => {
        dispatch('lmsSuperadminUsersBulkSetCustomInviteProcessBlocked', false)
        dispatch('lmsSuperadminUsersBulkSetCustomInviteProcessCompleted', false)
        dispatch('lmsSuperadminUsersBulkSetCustomInviteProcessPending', true)
        dispatch('lmsSuperadminUsersBulkSetCustomBlockWorkspace', true)
        dispatch('lmsSuperadminUsersBulkSetCustomBlockCustomer', true)
        dispatch('lmsSuperadminUsersBulkSetCustomViewMode', 'all')
        const refreshTokenInterval = setInterval(() => dispatch('authUserRefreshSecondStageToken'), 1000 * 60 * 5)
        const users = JSON.parse(JSON.stringify(state.data.users))
        const userProfilesWithUAOrMARole = getters.getLmsSuperadminUserProfilesWithUAOrMARole
        const selectedOrgUnitId = state.data.orgUnitId || undefined
        const selectedWorkspaceId = state.data.workspaceId || (userProfilesWithUAOrMARole.length === 1 ? getters.getAuthUserSecondStageTokenDataWorkspaceId : undefined)
        const selectedWorkspaceName = state.data.workspaceName || (userProfilesWithUAOrMARole.length === 1 ? getters.getAuthUserSecondStageTokenDataWorkspaceName : undefined)
        const selectedCustomerId = state.data.customerId || (userProfilesWithUAOrMARole.length === 1 ? getters.getAuthUserSecondStageTokenDataCustomerId : undefined)
        const selectedCustomerName = state.data.customerName || (userProfilesWithUAOrMARole.length === 1 ? getters.getAuthUserSecondStageTokenDataCustomerName : undefined)
        const suggestedCourses = state.data.suggestedCourses
        const orgUnitsSuggestedCourses = state.custom.orgUnitsSuggestedCourses
        const groupsSuggestedCourses = state.custom.groupsSuggestedCourses
        let inviteProcessBlocked
        let existsNotInvited = false
        let userIndex = 0
        let user
        let userData = {}

        const lmsSuperadminWorkspaceUserProfileCreate = (data) => {
          dispatch('lmsSuperadminUsersBulkSetUserStatus', { index: data.tableId, value: 'pending' })
          dispatch('lmsSuperadminWorkspaceUserProfileCreate', {
            redirect: false,
            notifications: false,
            emailAddress: data.emailAddress,
            firstName: data.firstName,
            lastName: data.lastName,
            invitedUser: data.invitedUser,
            workspaceGroups: data.workspaceGroups,
            roles: data.roles,
            managerOrganisationUnits: data.managerOrganisationUnits,
            unit: data.unit,
            suggestedCourses: data.suggestedCourses,
            customFields: data.customFields,
            toWorkspace: data.toWorkspace,
            workspaceName: data.workspaceName,
            customerId: data.customerId,
            customerName: data.customerName,
            status: data.status,
            bulk: true
          }).then(() => {
            dispatch('lmsSuperadminUsersBulkSetUserStatus', { index: data.tableId, value: 'invited' })
          }).catch(() => {
            dispatch('lmsSuperadminUsersBulkSetUserStatus', { index: data.tableId, value: 'not-invited' })
            existsNotInvited = true
          }).finally(() => {
            userIndex++
            userData = {}
            setTimeout(() => {
              performInviteProcess()
            }, 100)
          })
        }
        const performInviteProcess = () => {
          inviteProcessBlocked = state.custom.inviteProcessBlocked
          user = users[userIndex]

          if (userIndex > (users.length - 1) || inviteProcessBlocked) {
            if (inviteProcessBlocked) {
              reject(new Error('invitedProcessStopped'))
            } else if (existsNotInvited) {
              dispatch('defaultNotificationToastsAdd', {
                title: 'Bulk invite',
                message: `Some issues occurred due to bulk invite process. Please check data and try again or contact an administrator.`,
                status: 'error'
              })
              dispatch('lmsSuperadminUsersBulkSetCustomInviteProcessCompleted', true)
              reject(new Error('usersNotInvited'))
            } else {
              dispatch('lmsSuperadminUsersBulkSetCustomInviteProcessCompleted', true)
              resolve('usersInvited')
            }
            dispatch('defaultNotificationToastsAdd', {
              title: 'Bulk invite',
              message: `Bulk invite process has been completed.`,
              status: 'success'
            })
            dispatch('lmsSuperadminUsersBulkSetCustomInviteProcessPending', false)

            clearInterval(refreshTokenInterval)
            return
          }

          if (
            !user.firstName ||
          !user.lastName ||
          !user.emailAddress ||
          !rules.email.validate(user.emailAddress) ||
          user.status === 'invalid' ||
          user.status === 'invited' ||
          user.status === 'already-invited' ||
          user.invalidWorkspaceGroups.length ||
          user.invalidUnit.length
          ) {
            if (user.status === 'invalid') {
              existsNotInvited = true
            }
            if (user.status === 'invited') {
              dispatch('lmsSuperadminUsersBulkSetUserStatus', { index: user.tableId, value: 'already-invited' })
            }
            userIndex++
            performInviteProcess()
            return
          }

          dispatch('lmsSuperadminUsersBulkSetUserStatus', { index: user.tableId, value: 'pending' })

          setTimeout(() => {
            dispatch('authUserCheckExistsSSO', user.emailAddress).then(response => {
              const profiles = response.profiles
              const hasWorkspaceProfile = profiles.find(obj => {
                return obj.workspaceId === selectedWorkspaceId
              })
              if (hasWorkspaceProfile) {
                let newStatus
                switch (hasWorkspaceProfile.status) {
                  case 'invited':
                    newStatus = 'already-invited'
                    break
                  default:
                    newStatus = 'already-exists'
                    break
                }
                dispatch('lmsSuperadminUsersBulkSetUserStatus', { index: user.tableId, value: newStatus })
                userIndex++
                performInviteProcess()
              } else {
                userData.emailAddress = response.emailAddress
                userData.firstName = response.firstName
                userData.lastName = response.lastName
                userData.invitedUser = response.id
                userData.roles = user.roles
                userData.managerOrganisationUnits = user.managerOrganisationUnits
                userData.workspaceGroups = user.workspaceGroups.map(obj => obj.id)
                userData.unit = user.unit ? user.unit.id : selectedOrgUnitId
                userData.toWorkspace = selectedWorkspaceId
                userData.workspaceName = selectedWorkspaceName
                userData.customerId = selectedCustomerId
                userData.customerName = selectedCustomerName
                userData.suggestedCourses = user.unit && user.unit.id ? [] : suggestedCourses
                userData.status = 'sent'
                userData.index = userIndex
                userData.customFields = user.customFields
                userData.tableId = user.tableId

                if (user.workspaceGroups.length) {
                  for (let group of user.workspaceGroups) {
                    const groupId = group.id
                    const extendedGroup = groupsSuggestedCourses.find(obj => obj.id === groupId)
                    const extendedGroupSuggestedCourses = extendedGroup ? extendedGroup.suggestedCourses : []

                    userData.suggestedCourses = [...userData.suggestedCourses, ...extendedGroupSuggestedCourses]
                  }
                }

                if (user.unit && user.unit.id) {
                  const orgUnitId = user.unit.id
                  const extendedOrgUnit = orgUnitsSuggestedCourses.find(obj => obj.id === orgUnitId)
                  const extendedOrgUnitSuggestedCourses = extendedOrgUnit ? extendedOrgUnit.suggestedCourses : []

                  userData.suggestedCourses = [...userData.suggestedCourses, ...extendedOrgUnitSuggestedCourses]
                }

                lmsSuperadminWorkspaceUserProfileCreate(userData)
              }
            }).catch(() => {
              userData.emailAddress = user.emailAddress
              userData.firstName = user.firstName
              userData.lastName = user.lastName
              userData.invitedUser = ''
              userData.roles = user.roles
              userData.managerOrganisationUnits = user.managerOrganisationUnits
              userData.workspaceGroups = user.workspaceGroups.map(obj => obj.id)
              userData.unit = user.unit ? user.unit.id : selectedOrgUnitId
              userData.toWorkspace = selectedWorkspaceId
              userData.workspaceName = selectedWorkspaceName
              userData.customerId = selectedCustomerId
              userData.customerName = selectedCustomerName
              userData.suggestedCourses = user.unit && user.unit.id ? [] : suggestedCourses
              userData.status = 'sent'
              userData.index = userIndex
              userData.customFields = user.customFields
              userData.tableId = user.tableId

              if (user.workspaceGroups.length) {
                for (let group of user.workspaceGroups) {
                  const groupId = group.id
                  const extendedGroup = groupsSuggestedCourses.find(obj => obj.id === groupId)
                  const extendedGroupSuggestedCourses = extendedGroup ? extendedGroup.suggestedCourses : []

                  userData.suggestedCourses = [...userData.suggestedCourses, ...extendedGroupSuggestedCourses]
                }
              }

              if (user.unit && user.unit.id) {
                const orgUnitId = user.unit.id
                const extendedOrgUnit = orgUnitsSuggestedCourses.find(obj => obj.id === orgUnitId)
                const extendedOrgUnitSuggestedCourses = extendedOrgUnit ? extendedOrgUnit.suggestedCourses : []

                userData.suggestedCourses = [...userData.suggestedCourses, ...extendedOrgUnitSuggestedCourses]
              }

              lmsSuperadminWorkspaceUserProfileCreate(userData)
            })
          }, 100)
        }

        performInviteProcess()
      })
    }
  }
}

export default lmsSuperadminUsersBulk
